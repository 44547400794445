<script setup>
    import { getOrCreateDataObject } from 'o365-dataobject';
    
    //import  {OFilterString} from 'o365-filter-components';
    import {ORowContainer as RowContainer} from 'o365-ui-components';
    //import number from 'o365.modules.utils.number.js';
    import Marked from 'o365.vue.components.Marked.vue';
    import AzureTableStorageHandler from 'o365.libraries.modules.AzureTableStorageHandler.ts';
    import { useDataObjectEventListener } from 'o365-vue-utils';
    import { userSession } from 'o365-modules';
    import {computed} from 'vue';
    //import { Overlay } from 'o365.vue.components.jsx';

   const vHandler = new AzureTableStorageHandler({
        accountName: 'omega365appcache',
        entityName: 'atblSnippets',
        rowKey:'PrimKey',
        partitionKey:"Type"
    });

    const dsSnippets = getOrCreateDataObject({
        id:"dsSnippets",
        dataHandler:vHandler,
        disableLayouts: true,
        viewName:"dsSnippets",
        selectFirstRowOnLoad: true,
     
        clientSideFiltering:true,
        fields:[
            {name:"PrimKey"},{name:"Category"},{name:"Title"},{name:"Description"},{name:"SubCategory"},{name:"SearchColumn",maxLength:20000,caption:"Search Column"},{name:"Snippet"},{name:"Tags"},{name:"Type"},{name:"Timestamp",type:"dateTime",sortOrder:1,sortDirection:"asc"}
        ]
    });

    useDataObjectEventListener(dsSnippets, 'DataLoaded', () => {
        dsSnippets.state.rowCount = dsSnippets.data.length;
    });

     const dsSnippetsLocal = getOrCreateDataObject({
        id:"dsSnippetsLocal",

        viewName:"sviw_o365_CodeSnippets",
     
       // clientSideFiltering:true,
        fields:[
            {name:"PrimKey"},{name:"Category"},{name:"Title"},{name:"Description"},{name:"SubCategory"},{name:"SearchColumn",maxLength:20000,caption:"Search Column", alias:"_SearchColumn"},{name:"Snippet"},{name:"Tags"},{name:"Type"},{name:"Timestamp",type:"dateTime",sortOrder:1,sortDirection:"asc"}
        ],
        whereClause: "PersonID = -1"
    });

    dsSnippetsLocal.retrieve({viewName:"sviw_o365_CodeSnippets"}).then(ldata=>{
       /* ldata.forEach(row=>{
            dsSnippets.dataHandler.clientSideData.push(row);
        });*/
        const vData = [];
        dsSnippets.dataHandler.retrieve().then(data=>{
            
            
            data.forEach(row=>{
               // dsSnippets.storage.updateItemByPrimKey(row.PrimKey,{SearchColumn: row.Category+row.Tilte+row.Description+ row.SubCategory+ row.Category+ row.Snippet+row.Tags+row.Type},true);
               row['SearchColumn'] = row.Category 
                    ? row.Category?.concat(row.Title,row.Description,row.SubCategory,row.Category,row.Snippet,row.Tags,row.Type) 
                    : row.Title?.concat(row.Description,row.SubCategory,row.Category,row.Snippet,row.Tags,row.Type);
               vData.push(row);
            });
            ldata.forEach(row=>{
               // dsSnippets.dataHandler.clientSideData.push(row);
                if(row.Type == 'SQL') {
                    row.Snippet = "```sql"  + '\n' + row.Snippet  + '\n' + "```";
                    vData.push(row);

                } else {
                    vData.push(row);
                }
            });
              dsSnippets.enableClientSideHandler(vData);
        dsSnippets.load();
        });
      
    })


</script>
<template>
    <RowContainer class="p-2 position-relative">
        <ODataGrid :dataObject="dsSnippets" initialMenuWidth="70%" hideActionColumn hideSystemColumn hideMultiselectColumn gridMenuMinWidth="10&" gridMenuMaxWidth="90%"
            :fieldFilters="['Type', 'Category', 'SubCategory']"
            :detailTabTitle="$t('Snippet')">
            <template #cardheader>
                <FieldFilter :filterObject="dsSnippets.filterObject"  columnName="SearchColumn" class="me-2" hideDropdown></FieldFilter>
            </template>
            <template #detailTab>
                <Marked v-if="dsSnippets.current.Snippet" class="flex-1 marked" :value="dsSnippets.current.Snippet" style="overflow-y: auto;" noSanitize></Marked>
            </template>
            <OColumn field="Title" width="250" />
            <OColumn field="Description" width="300" :cellTitle="row => row.Description"/>
            <OColumn field="Type" hide />
            <OColumn field="Category" hide />
            <OColumn field="SubCategory" hide />
        </ODataGrid>
    <!--
    <Overlay v-if="dsSnippets.state.isLoading"/>
        <div  class="d-flex mb-2 " >
            <FieldFilter :filterObject="dsSnippets.filterObject"  columnName="SearchColumn" class="me-2" hideDropdown></FieldFilter>
            <FieldFilter :filterObject="dsSnippets.filterObject"  columnName="Title"  class="me-2"></FieldFilter>
            <FieldFilter :filterObject="dsSnippets.filterObject"  columnName="Type"  class="me-2"></FieldFilter>
            <FieldFilter :filterObject="dsSnippets.filterObject"  columnName="Category" ></FieldFilter>

        </div>
        <div class="flex-1 snippet-container" style="overflow-x: auto;">
            <div class="border rounded-0 mb-1" v-for="item in dsSnippets.data">
                <div class="bg-light-subtle collapsed" data-bs-toggle="collapse" :data-bs-target="'#_snippet_'+item.PrimKey" role="button">
                    <h4 class="px-2 pt-2 pb-0">{{ item.Title }}</h4>
                    <div class="fst-italic px-2 pb-2">{{ item.Type }} {{ item.Category }} {{ item.SubCategory }}  {{ item.Description }}</div>
                    
                </div>
                <div class="collapse border-top" :id="'_snippet_'+item.PrimKey">
                    <Marked class="flex-1 marked" :value="item.Snippet" style="overflow-y: auto;"></Marked>
                </div>
            </div>
        </div>
        <div class="d-flex bg-light-subtle border p-1 filterstring">
            <label class="me-2">Total: <span :title="$t('Filtered Rows')"> {{number.format(dsSnippets.rowCount, '1 234')}} </span> </label>
            <OFilterString :data-object="dsSnippets" class="" ></OFilterString>
        </div>
    -->
    </RowContainer>
</template>
<style >
    .snippet-container pre{
        margin:0!important;
    }
   
    .snippet-container .btnS:after {content: " less";}
    .snippet-container .btnS.collapsed:after {content: " more";}
    .filterstring {
        font-weight:bold;
    }
    .filterstring label{
        font-size:inherit!important;
    }

    .marked >*:not(div){
    padding:0.25rem;
    }
</style>